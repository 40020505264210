import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { db } from '../firebase/firebase-config'; // Adjust the import path according to your project structure
import { collection, getDocs, doc, updateDoc, getDoc, setDoc, arrayUnion } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import logAction from './logger'; // Adjust the import path if necessary
import './App.css';

const UpdateCustomerForm = () => {
  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [priceFiles, setPriceFiles] = useState([]);
  const [selectedPriceFile, setSelectedPriceFile] = useState(null);
  const [salesCodes, setSalesCodes] = useState([]);
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const auth = getAuth();
  const currentUser = auth.currentUser;

  const salesCodeOptions = [
    { value: '1', label: '1 - Adam Armstrong' },
    { value: '2', label: '2 - Oliver Harrison' },
    { value: '3', label: '3 - Joe Forte' },
    { value: '4', label: '4 - R & D Wightwick' },
    { value: '5', label: '5 - TBC' },
    { value: '6', label: '6 - Stuart Small' },
    { value: '7', label: '7 - John Dunn' },
    { value: '8', label: '8 - Teresa Richards' },
    { value: '9', label: '9 - John Dunn' },
    { value: 'CLOSED', label: 'Closed' },
    { value: 'TL', label: 'TL' },
    { value: 'SERVICE', label: 'Service' }
  ];

  // Fetch price files for the dropdown
  useEffect(() => {
    const fetchPriceFiles = async () => {
      const snapshot = await getDocs(collection(db, 'pricefiles'));
      const priceFilesData = snapshot.docs.map((doc) => ({
        value: doc.id,
        label: doc.id
      }));
      setPriceFiles(priceFilesData);
    };

    fetchPriceFiles();
  }, []);

  // Fetch customers for the dropdown
  useEffect(() => {
    const fetchCustomers = async () => {
      const snapshot = await getDocs(collection(db, 'customerinfo'));
      const customersData = snapshot.docs.map((doc) => ({
        value: doc.id,
        label: `${doc.id} - ${doc.data().name}`,
        data: doc.data()
      }));
      setCustomers(customersData);
    };

    fetchCustomers();
  }, []);

  const handleCustomerChange = (selectedOption) => {
    setSelectedCustomer(selectedOption);
    setSelectedPriceFile({ value: selectedOption.data.pricefile, label: selectedOption.data.pricefile });
    setSalesCodes((selectedOption.data.salescode || []).map(code => salesCodeOptions.find(option => option.value === code)));
    setEmail(selectedOption.data.email);
    setName(selectedOption.data.name);
  };

  const handleUpdateCustomer = async (e) => {
    e.preventDefault();
    if (!selectedCustomer || !selectedPriceFile || salesCodes.length === 0 || !email || !name) {
      alert('All fields are required.');
      return;
    }

    const customerDocRef = doc(db, 'customerinfo', selectedCustomer.value);

    await updateDoc(customerDocRef, {
      email,
      name,
      pricefile: selectedPriceFile.value,
      salescode: salesCodes.map(code => code.value)
    });

    // Update the corresponding 'pricefileAccess' document
    const pricefileAccessRef = doc(db, 'pricefileAccess', selectedPriceFile.value);
    const pricefileAccessSnap = await getDoc(pricefileAccessRef);

    if (pricefileAccessSnap.exists()) {
      // Retrieve existing arrays
      const existingCustomers = pricefileAccessSnap.data().customers || [];
      const existingSalesCodes = pricefileAccessSnap.data().salescodes || [];

      // Add only unique values
      await updateDoc(pricefileAccessRef, {
        customers: arrayUnion(selectedCustomer.value),
        salescodes: [...new Set([...existingSalesCodes, ...salesCodes.map(code => code.value)])]
      });
    } else {
      // Create a new document if it doesn't exist
      await setDoc(pricefileAccessRef, {
        customers: [selectedCustomer.value],
        salescodes: salesCodes.map(code => code.value)
      });
    }

    // Log the action
    if (currentUser) {
      const updaterDoc = await getDoc(doc(db, 'users', currentUser.uid));
      const updaterName = updaterDoc.exists() ? updaterDoc.data().name : 'Unknown User';
      await logAction(currentUser.uid, 'Customer Update', `Updated customer ${name} (ID: ${selectedCustomer.value}) with PriceFile: ${selectedPriceFile.value}, SalesCodes: ${salesCodes.map(code => code.value).join(', ')}`);
    }

    alert('Customer updated successfully!');
    // Clear form fields
    setSelectedCustomer(null);
    setSelectedPriceFile(null);
    setSalesCodes([]);
    setEmail('');
    setName('');
  };

  return (
    <div className="form-group text-center">
      <h1 className="text-center">Update Existing Customer</h1>

      <div className="login-form">
        <form onSubmit={handleUpdateCustomer}>
          <div className="form-group">
            <Select
              options={customers}
              value={selectedCustomer}
              onChange={handleCustomerChange}
              isSearchable
              placeholder="Select Customer"
              className="form-select"
            />
          </div>
          <div className="form-group">
            <input
              type="email"
              id="email"
              className="form-control"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="form-group">
            <input
              type="text"
              id="name"
              className="form-control"
              placeholder="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="form-group">
            <Select
              options={priceFiles}
              value={selectedPriceFile}
              onChange={setSelectedPriceFile}
              isSearchable
              placeholder="Price File"
              className="form-select"
            />
          </div>
          <div className="form-group">
            <Select
              options={salesCodeOptions}
              value={salesCodes}
              onChange={setSalesCodes}
              isMulti
              placeholder="Sales Codes"
              className="form-select"
            />
          </div>
          <button type="submit" className="btn btn-login">Update Customer</button>
        </form>
      </div>
    </div>
  );
};

export default UpdateCustomerForm;
