import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from './AuthContext';

const ProtectedRoute = ({ children, allowedRoles }) => {
  const { currentUser, userRoles, isApproved } = useAuth(); 

  // Ensure user is logged in
  if (!currentUser) {
    return <Navigate to="/" />;
  }

  // Check if the user is approved
  if (!isApproved) {
    return <Navigate to="/AwaitingApproval" />;
  }

  // Check if any of the user's roles match the allowed roles for the route
  if (allowedRoles && !userRoles.some(role => allowedRoles.includes(role))) {
    return <Navigate to="/Dashboard" />;
  }

  return children; // Render the child components if all conditions are met
};

export default ProtectedRoute;
