import React, { useState, useEffect } from 'react';
import { db } from '../firebase/firebase-config';
import { collection, getDocs } from 'firebase/firestore';
import UserApprovalForm from './UserApprovalForm';
import './App.css';

const UsersList = () => {
  const [users, setUsers] = useState([]);

  const fetchUsers = async () => {
    const querySnapshot = await getDocs(collection(db, 'users'));
    const usersData = querySnapshot.docs
      .map((doc) => ({ id: doc.id, ...doc.data() }))
      .filter((user) => !user.isApproved && !user.Denied); // Filter to show only pending approvals

    setUsers(usersData);
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const handleUserUpdate = () => {
    fetchUsers(); // Refresh the users list
  };

  return (
    <div>
      {users.length > 0 ? (
        users.map((user) => (
          <UserApprovalForm key={user.id} user={user} onUpdate={handleUserUpdate} />
        ))
      ) : (
        <p>No users waiting for approval.</p>
      )}
    </div>
  );
};

export default UsersList;
