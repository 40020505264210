import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { db } from '../firebase/firebase-config'; // Adjust the path to firebase config
import { collection, doc, getDoc, updateDoc, getDocs } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import logAction from './logger'; // Adjust path if necessary
import './App.css';

const salesPersonCodeOptions = [
  { value: '1', label: '1 - Adam Armstrong' },
  { value: '2', label: '2 - Oliver Harrison' },
  { value: '3', label: '3 - Joe Forte' },
  { value: '4', label: '4 - R & D Wightwick' },
  { value: '5', label: '5 - TBC' },
  { value: '6', label: '6 - Stuart Small' },
  { value: '7', label: '7 - John Dunn' },
  { value: '8', label: '8 - Teresa Richards' },
  { value: '9', label: '9 - John Dunn' },
  { value: 'CLOSED', label: 'Closed' },
  { value: 'TL', label: 'TL' },
  { value: 'SERVICE', label: 'Service' }
];

const EditUserFunction = ({ userId, onUpdate }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [company, setCompany] = useState('');
  const [address, setAddress] = useState('');
  const [postcode, setPostcode] = useState('');
  const [priceFiles, setPriceFiles] = useState([]);
  const [selectedPriceFile, setSelectedPriceFile] = useState(null);
  const [roles, setRoles] = useState([]);
  const [salesPersonCodes, setSalesPersonCodes] = useState([]);
  const [sales, setSales] = useState(false);
  const [admin, setAdmin] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const auth = getAuth();
  const currentUser = auth.currentUser;

  useEffect(() => {
    const fetchUserData = async () => {
      setIsLoading(true);
      const userDocRef = doc(db, 'users', userId);
      const userDoc = await getDoc(userDocRef);
      if (userDoc.exists()) {
        const userData = userDoc.data();
        setName(userData.name || '');
        setEmail(userData.email || '');
        setCompany(userData.companyname || '');
        setAddress(userData.address || '');
        setPostcode(userData.postcode || '');
        setRoles(userData.role || []);
        setSelectedPriceFile(userData.PriceFile ? { value: userData.PriceFile, label: userData.PriceFile } : null);
        setSalesPersonCodes((userData.salesPersonCodes || []).map(code => salesPersonCodeOptions.find(option => option.value === code)));
        setSales(userData.sales || false);
        setAdmin(userData.admin || false);
      }
      setIsLoading(false);
    };

    const fetchPriceFiles = async () => {
      const snapshot = await getDocs(collection(db, 'pricefiles'));
      const priceFilesData = snapshot.docs.map((doc) => ({
        value: doc.id,
        label: doc.id
      }));
      setPriceFiles(priceFilesData);
    };

    fetchUserData();
    fetchPriceFiles();
  }, [userId]);

  const handleSalesCheckboxChange = (e) => {
    const checked = e.target.checked;
    setSales(checked);

    if (checked) {
      setRoles((prevRoles) => [...new Set([...prevRoles, 'sales'])]);
    } else {
      setRoles((prevRoles) => prevRoles.filter(role => role !== 'sales'));
    }
  };

  const handleUpdateUser = async (e) => {
    e.preventDefault();
    if (!name || !email || !company || !address || !postcode || !selectedPriceFile) {
      alert('All fields are required.');
      return;
    }

    if (sales && salesPersonCodes.length === 0) {
      alert('Please select at least one Sales Person Code if the user is marked as a salesperson.');
      return;
    }

    const userRef = doc(db, 'users', userId);

    await updateDoc(userRef, {
      name,
      email,
      companyname: company,
      address,
      postcode,
      PriceFile: selectedPriceFile.value,
      role: [...new Set([...roles, 'customer'])], // Ensure 'customer' role is present
      sales,
      salesPersonCodes: salesPersonCodes.map(option => option.value)
    });

    // Log the action
    if (currentUser) {
      const updaterDoc = await getDoc(doc(db, 'users', currentUser.uid));
      const updaterName = updaterDoc.exists() ? updaterDoc.data().name : 'Unknown User';
      await logAction(currentUser.uid, 'User Update', `Updated user ${name} (ID: ${userId}) with PriceFile: ${selectedPriceFile.value}, SalesPersonCodes: ${salesPersonCodes.map(option => option.value).join(', ')}, Sales: ${sales}`);
    }

    alert('User updated successfully!');
    onUpdate(); // Trigger parent component refresh if necessary
  };

  return (
    <div className="form-group text-center">
      <h1 className="text-center">Edit User Information</h1>

      <div className="login-form">
        <form onSubmit={handleUpdateUser}>
          <div className="form-group">
            <input
              type="text"
              id="name"
              className="form-control"
              placeholder="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              disabled={admin} // Disable if user is admin
            />
          </div>
          <div className="form-group">
            <input
              type="email"
              id="email"
              className="form-control"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              disabled={admin} // Disable if user is admin
            />
          </div>
          <div className="form-group">
            <input
              type="text"
              id="company"
              className="form-control"
              placeholder="Company"
              value={company}
              onChange={(e) => setCompany(e.target.value)}
              disabled={admin} // Disable if user is admin
            />
          </div>
          <div className="form-group">
            <input
              type="text"
              id="address"
              className="form-control"
              placeholder="Address"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              disabled={admin} // Disable if user is admin
            />
          </div>
          <div className="form-group">
            <input
              type="text"
              id="postcode"
              className="form-control"
              placeholder="Postcode"
              value={postcode}
              onChange={(e) => setPostcode(e.target.value)}
              disabled={admin} // Disable if user is admin
            />
          </div>
          <div className="form-group">
            <Select
              options={priceFiles}
              value={selectedPriceFile}
              onChange={setSelectedPriceFile}
              isSearchable
              placeholder="Price File"
              className="form-select"
              isDisabled={admin} // Disable if user is admin
            />
          </div>
          <div className="form-group">
            <label>
              <input
                type="checkbox"
                checked={sales}
                onChange={handleSalesCheckboxChange}
                disabled={admin} // Disable if user is admin
              />{' '}
              Sales
            </label>
          </div>
          <div className="form-group">
            <Select
              options={salesPersonCodeOptions}
              value={salesPersonCodes}
              onChange={setSalesPersonCodes}
              isMulti
              placeholder="Sales Person Codes"
              className="form-select"
              isDisabled={!sales || admin} // Disable if user is not marked as a salesperson or is admin
            />
          </div>
          <button type="submit" className="btn btn-login" disabled={isLoading || admin}>Update User</button>
        </form>
      </div>
    </div>
  );
};

export default EditUserFunction;
