import { auth } from './firebase-config';
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
  sendEmailVerification,
} from 'firebase/auth';
import { doc, setDoc, getFirestore } from 'firebase/firestore';

const signUp = async (email, password) => {
  try {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;

    // Send a verification email
    await sendEmailVerification(user);

    // Update the Firestore document
    const firestore = getFirestore();
    await setDoc(doc(firestore, "users", user.uid), {
      email: email,
      emailVerified: false, // Initially false, will be updated upon verification
      // Other fields as needed
    });

    return userCredential;
  } catch (error) {
    console.error("Error signing up:", error.message);
    throw error;
  }
};

const logIn = async (email, password) => {
  try {
    return await signInWithEmailAndPassword(auth, email, password);
  } catch (error) {
    console.error("Error logging in:", error.message);
    throw error;
  }
};

const logOut = async () => {
  try {
    await signOut(auth);
  } catch (error) {
    console.error("Error logging out:", error.message);
    throw error;
  }
};

const resetPassword = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    alert("Password reset email sent!");
  } catch (error) {
    console.error("Error sending password reset email:", error.message);
    throw error;
  }
};

export {
  signUp,
  logIn,
  logOut,
  resetPassword,
};
