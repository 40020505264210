import React, { createContext, useContext, useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc, getFirestore } from 'firebase/firestore'; // Import Firestore functions

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [userRoles, setUserRoles] = useState([]); // State to store user roles
  const [isApproved, setIsApproved] = useState(false); // State to track approval status
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const auth = getAuth();
    const firestore = getFirestore();

    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setCurrentUser(user);

      if (user) {
        // Fetch user details from Firestore
        const userDocRef = doc(firestore, "users", user.uid); // Use UID for doc path
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists()) {
          const userData = userDoc.data();
          setUserRoles(userData.role || []);
          setIsApproved(userData.isApproved || false); // Set approval status
        } else {
          setUserRoles([]);
          setIsApproved(false); // Default to false if user data doesn't exist
        }
      } else {
        setUserRoles([]);
        setIsApproved(false); // Clear approval status if no user
      }

      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const authContextValue = { currentUser, userRoles, isApproved }; // Include isApproved in the context value

  return (
    <AuthContext.Provider value={authContextValue}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
