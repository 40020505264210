import React, { useState, useEffect } from 'react';
import { db } from '../firebase/firebase-config'; // Adjust the import path according to your project structure
import { doc, updateDoc, collection, getDocs, getDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import logAction from './logger'; // Adjust the import path if necessary
import './App.css';

const UserApprovalForm = ({ user, onUpdate }) => {
  const [priceFiles, setPriceFiles] = useState([]);
  const [selectedPriceFile, setSelectedPriceFile] = useState(user.PriceFile || '');
  const [isLoading, setIsLoading] = useState(false);
  const auth = getAuth();
  const currentUser = auth.currentUser;

  useEffect(() => {
    const fetchPriceFiles = async () => {
      const querySnapshot = await getDocs(collection(db, 'pricefiles'));
      const files = querySnapshot.docs.map((doc) => doc.id);
      setPriceFiles(files);
    };

    fetchPriceFiles();
  }, []);

  const handleDeny = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const userRef = doc(db, 'users', user.id);
      await updateDoc(userRef, {
        isApproved: false,
        Denied: true
      });

      // Log the action
      if (currentUser) {
        const approverDoc = await getDoc(doc(db, 'users', currentUser.uid));
        const approverName = approverDoc.exists() ? approverDoc.data().name : 'Unknown User';
        await logAction(currentUser.uid, 'User Denial', `Denied user ${user.name} (ID: ${user.id})`);
      }

      alert(`User ${user.name} denied.`);
      onUpdate(); // Trigger refresh in parent component
    } catch (error) {
      console.error('Error denying user:', error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const userRef = doc(db, 'users', user.id);
      const newRoles = Array.isArray(user.role) ? [...new Set([...user.role, 'customer'])] : ['customer'];

      await updateDoc(userRef, {
        isApproved: true,
        role: newRoles,
        customer: true,
        sales: false,
        PriceFile: selectedPriceFile
      });

      // Log the action
      if (currentUser) {
        const approverDoc = await getDoc(doc(db, 'users', currentUser.uid));
        const approverName = approverDoc.exists() ? approverDoc.data().name : 'Unknown User';
        await logAction(currentUser.uid, 'User Approval', `Approved user ${user.name} (ID: ${user.id}) with PriceFile: ${selectedPriceFile}`);
      }

      alert(`User approved with PriceFile: ${selectedPriceFile}`);
      onUpdate(); // Trigger refresh in parent component
    } catch (error) {
      console.error('Error approving user:', error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="signup-form">
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <h2>{user.name}</h2>
          <div><b>Email:</b> {user.email}</div>
          <div><b>Company:</b> {user.companyname}</div>
          <div><b>Address:</b> {user.address}, {user.postcode}</div>
        </div>
        <div className="form-group">
          <select
            value={selectedPriceFile}
            onChange={(e) => setSelectedPriceFile(e.target.value)}
            required
          >
            <option value="">Select a Price File</option>
            {priceFiles.map((fileId) => (
              <option key={fileId} value={fileId}>{fileId}</option>
            ))}
          </select>
        </div>
        <br />
        <button className="btn btn-login" type="submit" disabled={isLoading}>Approve User</button>
        <button className="btn btn-login" type="button" onClick={handleDeny} disabled={isLoading}>Deny User</button>
      </form>
    </div>
  );
};

export default UserApprovalForm;
