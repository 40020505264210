// logger.js
import { db } from '../firebase/firebase-config'; // Adjust the path as needed
import { collection, addDoc, doc, getDoc } from 'firebase/firestore';

const logAction = async (userId, action, details) => {
  try {
    // Retrieve user's name from Firestore
    const userDocRef = doc(db, 'users', userId);
    const userDoc = await getDoc(userDocRef);
    const userName = userDoc.exists() ? userDoc.data().name : 'Unknown User';

    // Log the action to Firestore
    const logCollection = collection(db, 'activityLogs');
    const now = new Date();
    await addDoc(logCollection, {
      userId,
      userName,
      action,
      details,
      timestamp: now.toISOString()
    });
  } catch (error) {
    console.error('Error logging action:', error.message);
  }
};

export default logAction;

