const AccessDenied = () => {
    return (
      <div>
        <h1>Access Denied</h1>
        <div className="login-form">
        <div className="form-group">
        <div className="container">
        <p>You do not have access to this price file. </p> <br /> <p>If you believe this is an error please contact ptaylor@telford-group.com</p>
        </div>
        </div>
        </div>
      </div>
    );
  };
  
  export default AccessDenied;