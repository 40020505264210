import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { db } from '../firebase/firebase-config'; // Adjust the path to firebase config
import { collection, getDocs } from 'firebase/firestore';
import EditUserFunction from './EditUserFunction';
import './App.css';

const EditUserForm = () => {
  const [users, setUsers] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState(null);

  useEffect(() => {
    const fetchUsers = async () => {
      const querySnapshot = await getDocs(collection(db, 'users'));
      const usersData = querySnapshot.docs.map((doc) => ({
        value: doc.id,
        label: doc.data().name || 'Unknown User'
      }));
      setUsers(usersData);
    };

    fetchUsers();
  }, []);

  const handleUserSelect = (selectedOption) => {
    setSelectedUserId(selectedOption ? selectedOption.value : null);
  };

  const handleUserUpdate = () => {
    // Refresh or handle updates as needed
    console.log('User updated');
  };

  return (
    <div className="form-group text-center">
      <h1 className="text-center">Select a User to Edit</h1>

      <div className="login-form">
          <Select
            options={users}
            value={users.find(user => user.value === selectedUserId) || null}
            onChange={handleUserSelect}
            isSearchable
            placeholder="Select a User"
            className="form-select"
          />
        </div>
        {selectedUserId && <EditUserFunction userId={selectedUserId} onUpdate={handleUserUpdate} />}
    </div>
  );
};

export default EditUserForm;
