import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { db } from '../firebase/firebase-config'; // Adjust the import path according to your project structure
import { collection, getDocs, doc, getDoc, setDoc, updateDoc, arrayUnion } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import logAction from './logger'; // Adjust the import path if necessary
import './App.css';

const CreateCustomerForm = () => {
  const [priceFiles, setPriceFiles] = useState([]);
  const [selectedPriceFile, setSelectedPriceFile] = useState(null);
  const [customerNumber, setCustomerNumber] = useState('');
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [salesCode, setSalesCode] = useState('');
  const auth = getAuth();
  const currentUser = auth.currentUser;

  const salesCodeOptions = [
    { value: '1', label: '1 - Adam Armstrong' },
    { value: '2', label: '2 - Oliver Harrison' },
    { value: '3', label: '3 - Joe Forte' },
    { value: '4', label: '4 - R & D Wightwick' },
    { value: '5', label: '5 - TBC' },
    { value: '6', label: '6 - Stuart Small' },
    { value: '7', label: '7 - John Dunn' },
    { value: '8', label: '8 - Teresa Richards' },
    { value: '9', label: '9 - John Dunn' },
    { value: 'CLOSED', label: 'Closed' },
    { value: 'TL', label: 'TL' },
    { value: 'SERVICE', label: 'Service' }
  ];

  // Fetch price files for the dropdown
  useEffect(() => {
    const fetchPriceFiles = async () => {
      const snapshot = await getDocs(collection(db, 'pricefiles'));
      const priceFilesData = snapshot.docs.map((doc) => ({
        value: doc.id,
        label: doc.id
      }));
      setPriceFiles(priceFilesData);
    };

    fetchPriceFiles();
  }, []);

  const handleCreateCustomer = async (e) => {
    e.preventDefault();
    if (!customerNumber || !email || !name || !selectedPriceFile || !salesCode) {
      alert('All fields are required.');
      return;
    }

    const customerDocRef = doc(db, 'customerinfo', customerNumber);
    const customerDocSnap = await getDoc(customerDocRef);

    if (customerDocSnap.exists()) {
      alert('Error: Customer number already exists.');
      return;
    }

    // Add new customer to 'customerinfo'
    await setDoc(customerDocRef, {
      email,
      name,
      pricefile: selectedPriceFile.value,
      salescode: salesCode.value
    });

    // Update the corresponding 'pricefileAccess' document
    const pricefileAccessRef = doc(db, 'pricefileAccess', selectedPriceFile.value);
    const pricefileAccessSnap = await getDoc(pricefileAccessRef);

    if (pricefileAccessSnap.exists()) {
      // Retrieve existing salescodes array
      const existingSalesCodes = pricefileAccessSnap.data().salescodes || [];
      const existingCustomers = pricefileAccessSnap.data().customers || [];

      // Only add the salesCode if it doesn't already exist
      const salesCodesToUpdate = existingSalesCodes.includes(salesCode.value)
        ? existingSalesCodes
        : [...existingSalesCodes, salesCode.value];

      await updateDoc(pricefileAccessRef, {
        customers: arrayUnion(customerNumber), // Add customer number uniquely
        salescodes: salesCodesToUpdate // Add sales code uniquely
      });
    } else {
      // Create a new document if it doesn't exist
      await setDoc(pricefileAccessRef, {
        customers: [customerNumber],
        salescodes: [salesCode.value]
      });
    }

    // Log the action
    if (currentUser) {
      const creatorDoc = await getDoc(doc(db, 'users', currentUser.uid));
      const creatorName = creatorDoc.exists() ? creatorDoc.data().name : 'Unknown User';
      await logAction(currentUser.uid, 'Customer Creation', `Created customer ${name} (ID: ${customerNumber}) with PriceFile: ${selectedPriceFile.value}, SalesCode: ${salesCode.value}`);
    }

    alert('Customer created successfully!');
    // Clear form fields
    setCustomerNumber('');
    setEmail('');
    setName('');
    setSelectedPriceFile(null);
    setSalesCode('');
  };

  return (
    <div className="form-group text-center">
      <h1 className="text-center">Create a New Customer</h1>

      <div className="login-form">
        <form onSubmit={handleCreateCustomer}>
          <div className="form-group">
            <input
              type="text"
              id="customerNumber"
              className="form-control"
              placeholder="Customer Account Number"
              value={customerNumber}
              onChange={(e) => setCustomerNumber(e.target.value)}
            />
          </div>
          <div className="form-group">
            <input
              type="email"
              id="email"
              className="form-control"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="form-group">
            <input
              type="text"
              id="name"
              className="form-control"
              placeholder="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="form-group">
            <Select
              options={priceFiles}
              value={selectedPriceFile}
              onChange={setSelectedPriceFile}
              isSearchable
              placeholder="Price File"
              className="form-select"
            />
          </div>
          <div className="form-group">
            <Select
              options={salesCodeOptions}
              value={salesCode}
              onChange={setSalesCode}
              placeholder="Sales Code"
              className="form-select"
            />
          </div>
          <button type="submit" className="btn btn-login">Create Customer</button>
        </form>
      </div>
    </div>
  );
};

export default CreateCustomerForm;
