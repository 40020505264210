import React, { useState } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase/firebase-config'; // Adjust this path to your Firebase config module

const CustomerExport = () => {
  const [isLoading, setIsLoading] = useState(false);

  const handleExport = async () => {
    setIsLoading(true);
    const snapshot = await getDocs(collection(db, "customerinfo"));
    const data = snapshot.docs.map(doc => ({
      docId: doc.id,
      ...doc.data()
    }));

    const csvContent = "data:text/csv;charset=utf-8,"
      + ["docId,pricefile,salescode"]
      .concat(data.map(item => `${item.docId},${item.pricefile},${item.salescode}`))
      .join("\n");

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "customerinfo.csv");
    link.click();
    
    setIsLoading(false);
  };

  return (
    <div>
      <button onClick={handleExport} disabled={isLoading}>
        {isLoading ? 'Exporting...' : 'Export CSV'}
      </button>
    </div>
  );
};

export default CustomerExport;
