import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { utils, writeFile } from 'xlsx';
import { db } from '../firebase/firebase-config';
import { doc, getDoc, collection, getDocs } from 'firebase/firestore';

const SalesPriceFile = () => {
  const { fileId } = useParams();
  const navigate = useNavigate();
  const [categorizedItems, setCategorizedItems] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [userSalesCodes, setUserSalesCodes] = useState([]);
  const [userCustomerCodes, setUserCustomerCodes] = useState([]);

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        const userDocRef = doc(db, "users", user.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setUserSalesCodes(Array.isArray(userData.salesPersonCodes) ? userData.salesPersonCodes : []);
          setUserCustomerCodes(Array.isArray(userData.customerCodes) ? userData.customerCodes : []);
        } else {
          console.error("No user data found!");
        }
      } else {
        console.error("User not logged in");
        navigate('/AccessDenied'); // Redirect to access denied if user not logged in
      }
    });
  }, [navigate]);

  useEffect(() => {
    const fetchProductInfoAndFile = async () => {
      try {
        setIsLoading(true);
        setError(null);

        // Check user access to the specific price file in pricefileAccess
        const priceFileAccessRef = doc(db, "pricefileAccess", fileId);
        const priceFileAccessSnap = await getDoc(priceFileAccessRef);

        if (priceFileAccessSnap.exists()) {
          const priceFileAccessData = priceFileAccessSnap.data();
          const allowedSalesCodes = priceFileAccessData.salescodes || [];
          const allowedCustomers = priceFileAccessData.customers || [];

          // Check if the user has access to this price file
          if (
            userSalesCodes.includes("admin") ||
            userSalesCodes.some(code => allowedSalesCodes.includes(code)) ||
            userCustomerCodes.some(code => allowedCustomers.includes(code))
          ) {
            // Fetch product info
            const productInfoSnapshot = await getDocs(collection(db, "productinfo"));
            const productInfo = {};
            productInfoSnapshot.forEach(doc => {
              const data = doc.data();
              productInfo[doc.id] = { userFriendlyDesc: data.userFriendlyDesc, category: data.category || 'Other' };
            });

            // Fetch the actual price file data from the pricefiles collection
            const priceFileRef = doc(db, "pricefiles", fileId);
            const priceFileSnap = await getDoc(priceFileRef);

            if (priceFileSnap.exists()) {
              organizeItemsByCategory(priceFileSnap.data().items || [], productInfo);
            } else {
              throw new Error("No such document in pricefiles collection!");
            }
          } else {
            setError("Access Denied: You do not have permission to view this price file.");
            navigate('/AccessDenied');
          }
        } else {
          throw new Error("No such document in pricefileAccess collection!");
        }
      } catch (error) {
        setError(`Failed to fetch data: ${error.message}`);
        console.error("Error:", error);
      }
      setIsLoading(false);
    };

    if (userSalesCodes.length > 0 || userCustomerCodes.length > 0) {
      fetchProductInfoAndFile();
    }
  }, [fileId, userSalesCodes, userCustomerCodes, navigate]);

  const organizeItemsByCategory = (items, productInfo) => {
    const categories = items.reduce((acc, item) => {
      let { itemcode, desc, unitprice } = item;
      itemcode = decodeURIComponent(itemcode);

      let productData = productInfo[itemcode] || productInfo[itemcode.replace(/\//g, '-')];
      const category = productData?.category || 'Other';
      const description = productData?.userFriendlyDesc || desc;
      if (!acc[category]) acc[category] = [];
      acc[category].push({ itemcode, desc: description, unitprice: Number(unitprice).toFixed(2) });

      return acc;
    }, {});

    setCategorizedItems(categories);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value.toLowerCase());
  };

  const exportTablesToExcel = () => {
    const wb = utils.book_new();
    const ws = utils.aoa_to_sheet([["Price File"]]);
    let currentRow = 3;
    const { Other, ...restCategories } = categorizedItems;
    const sortedCategories = Object.keys(restCategories).sort();

    Object.entries({ ...restCategories, Other }).forEach(([category, items]) => {
      utils.sheet_add_aoa(ws, [[category]], { origin: { r: currentRow++, c: 0 } });
      const itemsData = items.map(item => [item.itemcode, item.desc, `£${item.unitprice}`]);
      utils.sheet_add_aoa(ws, itemsData, { origin: { r: currentRow, c: 0 } });
      currentRow += itemsData.length + 2;
    });

    ws['!cols'] = [{ wch: 20 }, { wch: 100 }, { wch: 10 }];
    utils.book_append_sheet(wb, ws, "Price File");

    const filename = `${fileId}.xlsx`;
    writeFile(wb, filename);
  };

  const renderTable = (category, items) => {
    return (
      <div key={category}>
        <h3>{category}</h3>
        <table>
          <thead>
            <tr>
              <th>Item Code</th>
              <th>Description</th>
              <th>Price</th>
            </tr>
          </thead>
          <tbody>
            {items.map((item, index) => (
              <tr key={index}>
                <td>{item.itemcode}</td>
                <td>{item.desc}</td>
                <td>£{item.unitprice}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  const renderTables = () => {
    const { Other, ...restCategories } = categorizedItems;

    const filterItems = (items) => items.filter(item =>
      item.itemcode.toLowerCase().includes(searchQuery) ||
      item.desc.toLowerCase().includes(searchQuery)
    );

    const renderCategory = (category, items) => {
      const filteredItems = filterItems(items);
      if (filteredItems.length === 0) return null; // Don't render categories with no matching items
      return renderTable(category, filteredItems);
    };

    const sortedCategories = Object.keys(restCategories).sort();
    return (
      <div id="tables-to-export">
        {sortedCategories.map(category => renderCategory(category, restCategories[category]))}
        {Other && renderCategory('Other', Other)}
      </div>
    );
  };

  return (
    <div>
      <h1>Currently viewing {fileId}</h1>
      <button className='btn btn-login' onClick={exportTablesToExcel}>Export Tables as Excel</button>
      <input
        type="text"
        placeholder="Search by Code or Description..."
        value={searchQuery}
        onChange={handleSearchChange}
        className="search-bar"
      />
      {error && <p className="error">{error}</p>}
      {isLoading ? <p>Loading price file...</p> : renderTables()}
    </div>
  );
};

export default SalesPriceFile;
