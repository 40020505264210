import React, { useState, useEffect } from 'react';
import { db } from '../firebase/firebase-config'; // Adjust the path as needed
import { collection, getDocs, orderBy, query } from 'firebase/firestore';
import './App.css';

const ActivityLogViewer = () => {
  const [logs, setLogs] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    const fetchLogs = async () => {
      const q = query(collection(db, 'activityLogs'), orderBy('timestamp', 'desc'));
      const snapshot = await getDocs(q);
      const logsData = snapshot.docs.map((doc) => doc.data());
      setLogs(logsData);
    };

    fetchLogs();
  }, []);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value.toLowerCase());
  };

  const filteredLogs = logs.filter((log) =>
    log.userName.toLowerCase().includes(searchQuery) ||
    log.userId.toLowerCase().includes(searchQuery) ||
    log.action.toLowerCase().includes(searchQuery) ||
    log.details.toLowerCase().includes(searchQuery) ||
    new Date(log.timestamp).toLocaleString().toLowerCase().includes(searchQuery)
  );

  return (
    <div className="form-group text-center">
      <h1 className="text-center">Activity Logs</h1>

      <input
        type="text"
        placeholder="Search by User, Action, or Time..."
        value={searchQuery}
        onChange={handleSearchChange}
        className="form-control search-bar"
      />

      <div className="log-viewer">
        {filteredLogs.length > 0 ? (
          filteredLogs.map((log, index) => (
            <div key={index} className="log-entry-container">
              <div className="log-entry">
                <p><strong>User:</strong> {log.userName} (ID: {log.userId})</p>
                <p><strong>Action:</strong> {log.action}</p>
                <p><strong>Details:</strong> {log.details}</p>
                <p><strong>Timestamp:</strong> {new Date(log.timestamp).toLocaleString()}</p>
              </div>
            </div>
          ))
        ) : (
          <p>No logs found.</p>
        )}
      </div>
    </div>
  );
};

export default ActivityLogViewer;
