import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { collection, getDocs, query, where, doc, getDoc, arrayContainsAny } from 'firebase/firestore';
import { db } from '../firebase/firebase-config';
import './App.css';

const CustomerList = () => {
  const [customers, setCustomers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [userSalesCodes, setUserSalesCodes] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        const fetchUserData = async () => {
          const userDocRef = doc(db, "users", user.uid);
          const userDoc = await getDoc(userDocRef);
          if (userDoc.exists()) {
            const userData = userDoc.data();
            setUserSalesCodes(Array.isArray(userData.salesPersonCodes) ? userData.salesPersonCodes : []);
          } else {
            console.error("No user data found!");
          }
        };
        fetchUserData();
      } else {
        console.error("User not logged in");
      }
    });
  }, []);

  useEffect(() => {
    const fetchCustomers = async () => {
      if (userSalesCodes.length === 0) return;
      setIsLoading(true);

      let snapshot;
      if (userSalesCodes.includes('admin')) {
        // Fetch all customers for "admin"
        snapshot = await getDocs(collection(db, "customerinfo"));
      } else {
        // Fetch customers matching any of the user's sales codes
        const q = query(collection(db, "customerinfo"), where("salescode", "array-contains-any", userSalesCodes));
        snapshot = await getDocs(q);
      }

      const loadedCustomers = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setCustomers(loadedCustomers);
      setIsLoading(false);
    };
    fetchCustomers();
  }, [userSalesCodes]);

  const handlePriceFileAccess = async (customerId) => {
    const customerDocRef = doc(db, "customerinfo", customerId);
    const customerDoc = await getDoc(customerDocRef);

    if (customerDoc.exists()) {
      const customerData = customerDoc.data();
      const allowedSalesCodes = customerData.salescode || [];
      const pricefile = customerData.pricefile;

      // Admin access or allowed sales code(s)
      if (userSalesCodes.includes("admin") || userSalesCodes.some(code => allowedSalesCodes.includes(code))) {
        navigate(`/SalesPriceFile/${pricefile}`);
      } else {
        navigate('/AccessDenied'); // Replace with your appropriate path
      }
    } else {
      console.error(`Customer with ID ${customerId} does not exist`);
      navigate('/AccessDenied'); // Replace with your appropriate path
    }
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value.toLowerCase());
  };

  const filteredCustomers = customers.filter(customer =>
    customer.name.toLowerCase().includes(searchQuery)
  );

  if (isLoading) return <p>Loading...</p>;

  return (
    <div className="list-container">
      <input
        type="text"
        placeholder="Search Customers..."
        value={searchQuery}
        onChange={handleSearchChange}
        className="search-bar"
      />
      <ul>
        {filteredCustomers.map(customer => (
          <li key={customer.id} className="list-item" onClick={() => handlePriceFileAccess(customer.id)}>
            {customer.name}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CustomerList;
