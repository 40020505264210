import React, { useState } from 'react';
import { collection, getDocs, updateDoc, doc } from 'firebase/firestore';
import { db } from '../firebase/firebase-config'; // Adjust the import path according to your project structure
import './App.css';

const UpdateSalesCodesArray = () => {
  const [isUpdating, setIsUpdating] = useState(false);
  const [updateComplete, setUpdateComplete] = useState(false);
  const [error, setError] = useState(null);

  const handleUpdateSalesCodes = async () => {
    setIsUpdating(true);
    setUpdateComplete(false);
    setError(null);

    try {
      const customerCollection = collection(db, 'customerinfo');
      const customerSnapshot = await getDocs(customerCollection);

      // Batch update each customer's salescode field
      const updatePromises = customerSnapshot.docs.map(async (docSnapshot) => {
        const customerDocRef = doc(db, 'customerinfo', docSnapshot.id);
        const customerData = docSnapshot.data();

        if (customerData.salescode && !Array.isArray(customerData.salescode)) {
          await updateDoc(customerDocRef, {
            salescode: [customerData.salescode]
          });
        }
      });

      await Promise.all(updatePromises);

      setUpdateComplete(true);
    } catch (err) {
      console.error('Error updating sales codes:', err);
      setError('Error updating sales codes. Check the console for more details.');
    } finally {
      setIsUpdating(false);
    }
  };

  return (
    <div className="form-group text-center">
      <h1 className="text-center">Update All Sales Codes to Array Format</h1>

      <div className="login-form">
        <button
          className="btn btn-login"
          onClick={handleUpdateSalesCodes}
          disabled={isUpdating}
        >
          {isUpdating ? 'Updating...' : 'Update All Sales Codes'}
        </button>
        {updateComplete && !error && (
          <p className="success">Sales Codes updated successfully!</p>
        )}
        {error && <p className="error">{error}</p>}
      </div>
    </div>
  );
};

export default UpdateSalesCodesArray;
