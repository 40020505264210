import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, signOut } from 'firebase/auth';
import { doc, getDoc, getFirestore } from 'firebase/firestore';
import uploadIcon from './assets/upload.png';
import logIcon from './assets/log.png';
import userIcon from './assets/user.png';
import datasheetIcon from './assets/data-analysis.png';
import searchIcon from './assets/search-engine.png';
import changeIcon from './assets/changes.png';
import customerIcon from './assets/add-group.png';
import priceIcon from './assets/pound.png';
import approveIcon from './assets/check.png';
import './App.css';

const Dashboard = () => {
    const auth = getAuth();
    const navigate = useNavigate();
    const [userData, setUserData] = useState(null);


    const handleLogout = async () => {
        try {
          await signOut(auth);
          navigate('/'); // Redirect to the login route
        } catch (error) {
          console.error('Logout Failed:', error);
          // Handle logout errors
        }
      };

      const handlePriceFileListClick = () => {
        navigate('/PriceFileList');
      };

      const handleCustomerListClick = () => {
        navigate('/CustomerList');
      };

      const handleUploadCSVClick = () => {
        navigate('/UploadCSV');
      };

      const handleDatasheetClick = () => {
        window.open('https://www.telford-group.com/downloads/cylinder-data-sheets/', '_blank');
      };

      const handleUsersListClick = () => {
        navigate('/UsersList')
      };

      const handleFirestoreUploadClick = () => {
        navigate('/UploadFirestoreCSV')
      };

      const handleSalesCodeUploadClick = () => {
        navigate('/UpdateSalesCode')
      };

      const handleCustomerExportClick = () => {
        navigate('/CustomerExport')
      };

      const handleCustomerUploadClick = () => {
        navigate('/UploadCustomerInfo')
      };

      const handleCustomerUploadCSVClick = () => {
        navigate('/UploadCustomerCSV')
      };

      const handleEditUserClick = () => {
        navigate('/EditUserForm')
      };

      const handleNewCustomerClick = () => {
        navigate('/CreateCustomerForm')
      };

      const handleUpdateCustomerClick = () => {
        navigate('/UpdateCustomerForm')
      };

      const handleUpdateSalesArrayClick = () => {
        navigate('/UpdateSalesCodesArray')
      };

      const handleActivityClick = () => {
        navigate('/ActivityLogViewer')
      };

      useEffect(() => {
        const fetchData = async () => {
          const userUid = auth.currentUser?.uid; // Get the user's UID instead of email
          if (userUid) {
            const db = getFirestore();
            const userDocRef = doc(db, "users", userUid); // Use UID to reference the document
            const userDoc = await getDoc(userDocRef);
      
            if (userDoc.exists()) {
              const userData = userDoc.data();
              setUserData(userData); // This now includes the role
            } else {
              console.log("No user data found!");
            }
          }
        };
      
        fetchData();
      }, [auth]);
      

        return (
            <div className="dashboard-container">
              <div className="dashboard-header">
                <div className="header-title">
                  <h1>{userData && userData.name ? `${userData.name}'s Dashboard` : 'Dashboard'}</h1>
                </div>
                <div className="header-actions">

                  <button onClick={handleLogout} className="logout-button">Log Out</button>
                  {/* Favourites Modal */}
                </div>

                <div className="widget-container">
        {userData && (
            <>
            {userData.sales && (
              <div className="widget" onClick={handleCustomerListClick}>
                <img src={searchIcon} alt="Help Desk" />
                <p>Search by Customer Name</p>
                {/* Additional content for Helpdesk */}
              </div>
            )}
            {userData.admin && (
              <div className="widget" onClick={handlePriceFileListClick}>
                <img src={priceIcon} alt="Help Desk" />
                <p>Customer Price Files</p>
                {/* Additional content for Helpdesk */}
              </div>
            )}
            {userData.sales && (
              <div className="widget" onClick={handleDatasheetClick}>
                <img src={datasheetIcon} alt="Help Desk" />
                <p>Datasheets</p>
                {/* Additional content for Helpdesk */}
              </div>
            )}
            {userData.admin && (
              <div className="widget" onClick={handleNewCustomerClick}>
                <img src={customerIcon} alt="Help Desk" />
                <p>New Customer</p>
                {/* Additional content for Helpdesk */}
              </div>
            )}
            {userData.admin && (
              <div className="widget" onClick={handleUpdateCustomerClick}>
                <img src={changeIcon} alt="Help Desk" />
                <p>Update Existing Customer</p>
                {/* Additional content for Helpdesk */}
              </div>
            )}
              {userData.admin && (
                <div className="widget" onClick={handleUploadCSVClick}>
                  <img src={uploadIcon} alt="Help Desk" />
                  <p>Upload CSV to Price File</p>
                  {/* Additional content for Helpdesk */}
                </div>
            )}
            {userData.admin && (
                <div className="widget" onClick={handleEditUserClick}>
                  <img src={userIcon} alt="Help Desk" />
                  <p>Edit a User</p>
                  {/* Additional content for Helpdesk */}
                </div>
            )}
            {userData.admin && (
                <div className="widget" onClick={handleUsersListClick}>
                  <img src={approveIcon} alt="Help Desk" />
                  <p>User Approvals</p>
                  {/* Additional content for Helpdesk */}
                </div>
            )}
            {userData.peter && (
                <div className="widget" onClick={handleFirestoreUploadClick}>
                  <img src={uploadIcon} alt="Help Desk" />
                  <p>Upload to Firestores</p>
                  {/* Additional content for Helpdesk */}
                </div>
            )}
            {userData.peter && (
                <div className="widget" onClick={handleUpdateSalesArrayClick}>
                  <img src={uploadIcon} alt="Help Desk" />
                  <p>Update Sales Array</p>
                  {/* Additional content for Helpdesk */}
                </div>
            )}
            {userData.peter && (
                <div className="widget" onClick={handleSalesCodeUploadClick}>
                  <img src={uploadIcon} alt="Help Desk" />
                  <p>Update Sales Code</p>
                  {/* Additional content for Helpdesk */}
                </div>
            )}
            {userData.peter && (
                <div className="widget" onClick={handleCustomerExportClick}>
                  <img src={uploadIcon} alt="Help Desk" />
                  <p>Customer Export</p>
                  {/* Additional content for Helpdesk */}
                </div>
            )}
            {userData.peter && (
                <div className="widget" onClick={handleCustomerUploadClick}>
                  <img src={uploadIcon} alt="Help Desk" />
                  <p>Upload Customer Info</p>
                  {/* Additional content for Helpdesk */}
                </div>
            )}
            {userData.peter && (
                <div className="widget" onClick={handleCustomerUploadCSVClick}>
                  <img src={uploadIcon} alt="Help Desk" />
                  <p>Upload Customer Info CSV</p>
                  {/* Additional content for Helpdesk */}
                </div>
            )}
            {userData.peter && (
                <div className="widget" onClick={handleActivityClick}>
                  <img src={logIcon} alt="Help Desk" />
                  <p>Activity Log</p>
                  {/* Additional content for Helpdesk */}
                </div>
            )}
        </>
    )}
</div>
</div>
</div>

  )};
  
  export default Dashboard;