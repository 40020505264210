import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase/firebase-config';
import './App.css';

const SalesPriceFileList = () => {
  const [priceFiles, setPriceFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value.toLowerCase());
  };

  const filteredFiles = priceFiles.filter(file => 
    file.toLowerCase().includes(searchQuery)
  );
  

  useEffect(() => {
    const fetchPriceFiles = async () => {
      setIsLoading(true);
      const snapshot = await getDocs(collection(db, "pricefiles"));
      const files = snapshot.docs.map(doc => doc.id);
      setPriceFiles(files);
      setIsLoading(false);
    };

    fetchPriceFiles();
  }, []);

  if (isLoading) return <p>Loading...</p>;

  return (
    // For the search input and the list
<div className="list-container">
  <input 
    type="text" 
    placeholder="Search Price Files..." 
    value={searchQuery} 
    onChange={handleSearchChange} 
    className="search-bar"
  />
  <ul>
  {filteredFiles.map((fileId) => (
    <li key={fileId} className="list-item">
      <Link to={`/SalesPriceFile/${fileId}`}>{fileId}</Link>
    </li>
  ))}
</ul>
</div>
  );
};

export default SalesPriceFileList;
