import React, { useState } from 'react';
import Papa from 'papaparse';
import './App.css';
import { db } from '../firebase/firebase-config'; // Adjust the import path according to your project structure
import { doc, getDoc, updateDoc, collection, query, where, getDocs } from 'firebase/firestore'; // Import Firestore collection handling functions

const UpdateSalesCodeComponent = () => {
  const [file, setFile] = useState(null);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleUpload = () => {
    if (!file) {
      alert("No file selected!");
      return;
    }

    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: async (result) => {
        console.log('Parsed CSV data:', result.data);
    
        for (const item of result.data) {
          const { pricefile, salescode } = item;
          if (!pricefile || !salescode) {
            console.log('Skipping incomplete record:', item);
            continue;
          }

          const docRef = doc(db, "customerinfo", pricefile);
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            console.log('Updating sales code for:', pricefile);
            await updateDoc(docRef, { salescode });
          } else {
            console.log('No document matches the pricefile:', pricefile);
            continue;
          }
        }
        alert('Update successful!');
      },
      error: (error) => {
        console.error('Error parsing CSV:', error);
        alert('Failed to parse CSV.');
      }
    });
  };

  const findMissingSalesCodes = async () => {
    const q = query(collection(db, "customerinfo"), where("salescode", "==", null));
    const querySnapshot = await getDocs(q);
    if (querySnapshot.empty) {
      console.log('No documents are missing a salescode.');
      alert('All documents have a salescode.');
    } else {
      console.log('Documents missing a salescode:');
      querySnapshot.forEach((doc) => {
        console.log(doc.id);
      });
      alert(`Found ${querySnapshot.size} documents without a salescode.`);
    }
  };

  return (
    <div>
      <input type="file" accept=".csv" onChange={handleFileChange} /> <br/><br/>
      <button className='btn btn-login' onClick={handleUpload}>Update CSV</button> < br/> < br/>
      <button className='btn btn-login' onClick={findMissingSalesCodes}>Check Missing Salescodes</button>
    </div>
  );
};

export default UpdateSalesCodeComponent;
