const AwaitingApproval = () => {
    return (
      <div>
        <h1>Thank You for Signing Up!</h1>
        <div className="login-form">
        <div className="form-group">
        <div className="container">
        <p>Your account is currently awaiting approval. This process can take up to 48 hours.</p> <br /> <p> Please ensure you validate your email. </p> <br /> <p>In the meantime, contact our office 01952 257961 or email us: sales@telford-group.com.</p>
        </div>
        </div>
        </div>
      </div>
    );
  };
  
  export default AwaitingApproval;