import React, { useState } from 'react';
import { collection, doc, setDoc } from 'firebase/firestore';
import { db } from '../firebase/firebase-config';
import Papa from 'papaparse';

const UploadPriceFileAccess = () => {
  const [csvFile, setCsvFile] = useState(null);

  const handleFileChange = (e) => {
    setCsvFile(e.target.files[0]);
  };

  const uploadCsvToFirestore = async (e) => {
    e.preventDefault();
    if (!csvFile) return;

    Papa.parse(csvFile, {
      header: true,
      skipEmptyLines: true,
      complete: async (results) => {
        const data = results.data;
        const pricefileAccessMap = {};

        data.forEach(row => {
          const customer = row['customers'];
          const pricefile = row['pricefile'];
          const salescode = row['salescode'];

          if (!pricefileAccessMap[pricefile]) {
            pricefileAccessMap[pricefile] = {
              customers: [],
              salescodes: []
            };
          }

          if (!pricefileAccessMap[pricefile].customers.includes(customer)) {
            pricefileAccessMap[pricefile].customers.push(customer);
          }

          if (!pricefileAccessMap[pricefile].salescodes.includes(salescode)) {
            pricefileAccessMap[pricefile].salescodes.push(salescode);
          }
        });

        const collectionRef = collection(db, 'pricefileAccess');
        for (const [pricefile, { customers, salescodes }] of Object.entries(pricefileAccessMap)) {
          await setDoc(doc(collectionRef, pricefile), { customers, salescodes });
        }

        alert('Price file access data uploaded successfully!');
      },
      error: (error) => {
        console.error('Parsing error:', error.message);
      }
    });
  };

  return (
    <div>
      <h2>Upload Price File Access</h2>
      <form onSubmit={uploadCsvToFirestore}>
        <input type="file" accept=".csv" onChange={handleFileChange} />
        <button type="submit">Upload</button>
      </form>
    </div>
  );
};

export default UploadPriceFileAccess;
