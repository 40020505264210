import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { auth } from '../firebase/firebase-config'; // Adjust the path as necessary
import './App.css'; // Ensure you have imported your CSS file

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

const navigateToSignUp = () => {
    navigate('/SignUp');
};

const handleForgotPassword = () => {
    navigate('/ForgotPassword');
    console.log('Forgot Password')
};

  const handleLogin = async () => {
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Immediately check if the email is verified
      if (!user.emailVerified) {
        alert("Please verify your email address.");
        // Optionally, navigate to a verification notice page or show a modal/pop-up
        return;
      }

      // Get Firestore instance
      const firestore = getFirestore();
      // Get the user's document from Firestore
      const userRef = doc(firestore, "users", user.uid);
      const docSnap = await getDoc(userRef);

      if (docSnap.exists()) {
        const userData = docSnap.data();
        // Check if the user's email has been verified and they are approved
        if (userData.isApproved) {
          console.log("User is approved.");
          navigate('/Dashboard'); // Navigate to the Dashboard page
        } else {
          console.log("User is not approved yet.");
          navigate('/AwaitingApproval'); // Navigate to the AwaitingApproval page
        }
      } else {
        console.log("No user data found in Firestore.");
        // Handle case where user document does not exist, perhaps navigate to an error page or show a message
      }
    } catch (error) {
      console.error("Error logging in:", error.message);
      // Optionally, show error feedback to the user (e.g., invalid credentials, user not found)
    }
  };

  return (

    <div className="form-group text-center">

        <h1 className="text-center">Telford Copper & Stainless Cylinders LTD</h1> 

            <div className="login-form">

                <div className="form-group">

                    <input type="email" id="email" className="form-control" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />

                </div>

                <div className="form-group">

                    <input type="password" id="password" className="form-control" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />

                </div>

                <div className="btn-group">

                    <button className="btn btn-login" onClick={handleLogin}>Log In</button>

                    <button className="btn btn-login" onClick={navigateToSignUp}>Sign Up</button>

                </div>

                    <button className="btn btn-login btn-full-width" onClick={handleForgotPassword}>Forgot Password</button>

            </div>

        </div>

);

}

export default LoginPage;
