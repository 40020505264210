import React, { useState } from 'react';
import Papa from 'papaparse';
import { db } from '../firebase/firebase-config'; // Adjust the import path according to your project structure
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import logAction from './logger';

const UploadCSV = () => {
  const [file, setFile] = useState(null);
  const auth = getAuth();
  const user = auth.currentUser;

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  // Define field mappings for flexible headers
  const fieldMapping = {
    itemcode: ['itemcode', 'Item Code', 'item_code', 'ItemCode'],
    desc: ['desc', 'Description', 'description', 'Desc'],
    unitprice: ['unitprice', 'Unit Price', 'unit_price', 'UnitPrice'],
    startdate: ['startdate', 'Start Date', 'start_date', 'StartDate', 'Starting Date'],
    enddate: ['enddate', 'End Date', 'end_date', 'EndDate', 'Ending Date'],
    pricefilecode: ['pricefilecode', 'Price File Code', 'price_file_code', 'PriceFileCode', 'Sales Code', 'salescode']
  };

  const normalizeHeader = (header) => {
    return header?.trim().toLowerCase().replace(/\s+/g, '');
  };

  const normalizeField = (field) => {
    return field?.trim();
  };

  const mapHeaders = (headers) => {
    const mapped = {};
    Object.keys(fieldMapping).forEach((key) => {
      const possibleHeaders = fieldMapping[key].map(normalizeHeader);
      const header = headers.find((h) => possibleHeaders.includes(normalizeHeader(h)));
      if (header) {
        mapped[key] = header.trim();
      }
    });
    return mapped;
  };

  const handleUpload = () => {
    if (!file) return;

    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: async (result) => {
        const headers = result.meta.fields.map(h => h.trim());
        const mappedHeaders = mapHeaders(headers);

        console.log('Parsed CSV data:', result.data);

        const defaultEndDate = '01/01/2030';

        // Organize data by pricefilecode
        const itemsByPriceFile = result.data.reduce((acc, item) => {
          const pricefilecode = normalizeField(item[mappedHeaders.pricefilecode]) || '';
          const itemcode = normalizeField(item[mappedHeaders.itemcode]) || 'Unknown';
          const desc = normalizeField(item[mappedHeaders.desc]) || 'Unknown';
          const unitprice = parseFloat(normalizeField(item[mappedHeaders.unitprice])) || 0;
          const startdate = normalizeField(item[mappedHeaders.startdate]) || '';
          const enddate = normalizeField(item[mappedHeaders.enddate]) || defaultEndDate;

          if (!pricefilecode.length) {
            // If pricefilecode is undefined or blank (or only contains whitespace), skip this item
            return acc;
          }

          const itemData = { itemcode, desc, unitprice, startdate, enddate };

          // Log the fields to check which one is causing the invalid condition
          console.log(`Checking item: ${JSON.stringify(itemData)}`);

          if (itemcode !== 'Unknown' && desc !== 'Unknown' && unitprice !== 0 && startdate !== '') {
            if (!acc[pricefilecode]) {
              acc[pricefilecode] = {
                items: [],
                customers: [], // Initialize empty customers array
                salescodes: [] // Initialize empty salescodes array
              };
            }
            acc[pricefilecode].items.push(itemData);
          } else {
            console.warn(`Skipping invalid item: ${JSON.stringify(itemData)}`);
          }

          return acc;
        }, {});

        // Process each price file's data
        for (const [pricefilecode, data] of Object.entries(itemsByPriceFile)) {
          const accessDocRef = doc(db, 'pricefileAccess', pricefilecode);
          const accessDocSnap = await getDoc(accessDocRef);

          if (!accessDocSnap.exists()) {
            await setDoc(accessDocRef, data); // Create a new document in pricefileAccess
          } else {
            console.log(`Document ${pricefilecode} already exists in pricefileAccess, skipping creation.`);
          }

          // Always overwrite the existing document in pricefiles
          const priceFileDocRef = doc(db, 'pricefiles', pricefilecode);
          await setDoc(priceFileDocRef, { items: data.items }, { merge: false }); // Overwrite in pricefiles
          console.log(`Document ${pricefilecode} created/updated in pricefiles`);
        }

        // Log the action
        if (user) {
          await logAction(user.uid, 'CSV Upload', `Uploaded ${file.name}`);
        }

        alert('Upload successful!');
      },
      error: (error) => {
        console.error('Parsing error:', error.message);
      }
    });
  };

  return (
    <div>
      <input type="file" accept=".csv" onChange={handleFileChange} /> <br /> <br />
      <button className='btn btn-login' onClick={handleUpload}>Upload CSV</button>
    </div>
  );
};

export default UploadCSV;