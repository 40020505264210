// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration (replace with your own config object)
const firebaseConfig = {
    apiKey: "AIzaSyA4H_74udbcHbLfYlpq96UWBH-ZR1uAl_0",
    authDomain: "telford-intranet.firebaseapp.com",
    projectId: "telford-intranet",
    storageBucket: "telford-intranet.appspot.com",
    messagingSenderId: "805888336327",
    appId: "1:805888336327:web:e6cac1da109efeb743b9e2",
    measurementId: "G-W2ZY8FZF7H"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);

// Initialize Firestore and get a reference to the service
const db = getFirestore(app);

export { auth, db };
