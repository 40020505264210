import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { signUp } from '../firebase/AuthService';
import { doc, setDoc, getFirestore } from 'firebase/firestore'; // Make sure to import these

const SignUpComponent = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [companyname, setCompanyName] = useState('');
  const [address, setAddress] = useState('');
  const [postcode, setPostcode] = useState('');
  const [error, setError] = useState(''); // State to store the error message
  const navigate = useNavigate();

  const handleSignUp = async (e) => {
    e.preventDefault();
    setError(''); // Reset error message before trying to sign up

    const passwordRegex = /^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9])(?=.*[a-z]).{8,}$/;
    if (!passwordRegex.test(password)) {
      setError('Password must contain at least 8 characters, including one uppercase letter, one symbol, and one number.');
      return;
    }

    try {
      const userCredential = await signUp(email, password);
      const user = userCredential.user;
      console.log('User created successfully:');

      const firestore = getFirestore();
      await setDoc(doc(firestore, "users", user.uid), {
        name: name,
        email: email,
        isApproved: false,
        emailVerified: false,
        companyname: companyname,
        address: address,
        postcode: postcode,
        role: []
      });

      navigate('/AwaitingApproval');
    } catch (error) {
      console.error("Failed to create user:", error.message);
      if (error.code === 'auth/email-already-in-use') {
        setError('The email address is already in use by another account.');
      } else {
        setError('Failed to sign up. Please try again later.');
      }
    }
  };

  return (
    <form onSubmit={handleSignUp} className="signup-form">
      <p><b>Your Details</b></p>
      <input type="text" value={name} onChange={(e) => setName(e.target.value)} placeholder="Name - (John Doe)" required />
      <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email - (sales@telford-group.com)" required />
      <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password - (Capital, Number & Symbol)" required />
      <p><b>Company Details</b></p>
      <input type="text" value={companyname} onChange={(e) => setCompanyName(e.target.value)} placeholder="Business Name - (The Plumbing Merchant)" required />
      <input type="text" value={address} onChange={(e) => setAddress(e.target.value)} placeholder="Business Address - (123 Hot Water Lane, Telford)" required />
      <input type="text" value={postcode} onChange={(e) => setPostcode(e.target.value)} placeholder="Business Postcode - (TF2 1FE)" required />
      <button type="submit" className="btn">Sign Up</button> < br/>
      {error && <p className="error" style={{ color: 'red', fontSize: '1rem', fontWeight: 'bold' }}>{error}</p>}
    </form>
  );
};

export default SignUpComponent;
