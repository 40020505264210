import React, { useState } from 'react';
import Papa from 'papaparse';
import './App.css';
import { db } from '../firebase/firebase-config'; // Adjust the import path according to your project structure
import { doc, setDoc } from 'firebase/firestore'; // Make sure to import these

const UploadCustomerInfo = () => {
  const [file, setFile] = useState(null);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleSendHelp = () => {
    alert("Make sure your CSV file has the columns: account, email, name, and pricefile.")
  }

  const handleUpload = () => {
    if (!file) {
      alert("No file selected!");
      return;
    }

    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: async (result) => {
        console.log('Parsed CSV data:', result.data);
    
        // Upload each item to Firestore
        for (const item of result.data) {
          const { account, email, name, pricefile } = item;

          if (!account || !email || !name || !pricefile) {
            console.log('Skipping incomplete record:', item);
            continue; // Skip to the next iteration if any field is missing
          }

          console.log('Uploading account:', account);
          const docRef = doc(db, "customerinfo", account); // Using "account" as document ID
          await setDoc(docRef, { email, name, pricefile });
        }
        alert('Upload successful!');
      },
      error: (error) => {
        console.error('Error parsing CSV:', error);
        alert('Failed to parse CSV.');
      }
    });
  };

  return (
    <div>
      <input type="file" accept=".csv" onChange={handleFileChange} /> <br/><br/>
      <button className='btn btn-login' onClick={handleUpload}>Upload CSV</button> <br/><br/>
      <button className='btn btn-login' onClick={handleSendHelp}>Help Me!</button>
    </div>
  );
};

export default UploadCustomerInfo;
