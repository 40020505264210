import React, { useState } from 'react';
import Papa from 'papaparse';
import './App.css';
import { db } from '../firebase/firebase-config'; // Adjust the import path according to your project structure
import { doc, setDoc } from 'firebase/firestore'; // Make sure to import these

const UploadFirestoreCSV = () => {
  const [file, setFile] = useState(null);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleSendHelp = () => {
    alert("Your excel document needs to have the following items docId, userFriendlyDesc and category")
  }

  const handleUpload = () => {
    if (!file) return;

    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: async (result) => {
        console.log('Parsed CSV data:', result.data);
    
        // Upload each item to Firestore
        for (const item of result.data) {
          const { docId, userFriendlyDesc, category } = item;


          if (!docId || !userFriendlyDesc || !category) {
            console.log('Skipping empty line:', item);
            continue; // Skip to next iteration
          }

          console.log('docId:', docId);
          const docRef = doc(db, "productinfo", docId);
          await setDoc(docRef, { userFriendlyDesc, category });
        }
        alert('Upload successful!');
      },
    });
  };

  return (
    <div>
      <input type="file" accept=".csv" onChange={handleFileChange} /> < br/> < br/>
      <button className='btn btn-login' onClick={handleUpload}>Upload CSV</button> < br/> < br/>
      <button className='btn btn-login' onClick={handleSendHelp}>Help Me!</button>
    </div>
  );
};

export default UploadFirestoreCSV;
